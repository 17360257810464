import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import styled from "styled-components"
import { colors } from "@yarno/thread"
const primaryColor = colors.greenLightSea

const AlgoliaSearch = ({
  refine,
  currentRefinement,
  onFocus,
  hasFocus,
  placeholder
}) => {
  const placeholderFallback = "I want to reset my password..."

  return (
    <Form hasFocus={hasFocus}>
      <input
        type="text"
        placeholder={placeholder || placeholderFallback}
        aria-label="Search"
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
    </Form>
  )
}

export default connectSearchBox(AlgoliaSearch)

const Form = styled.form`
  margin-bottom: 0;
  height: 5rem;
  width: 100%;
  border-radius: 7px;
  outline: none;
  position: relative;

  &:before {
    position: absolute;
    z-index: 2;
    font-family: Jupiter;
    content: "\f03e";
    color: ${primaryColor};
    padding: 0 1.5rem;
    line-height: 5rem;
  }

  input {
    outline: none;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    padding: 1.4rem 1.4rem 1.4rem 4rem;
    font-size: 1.6rem;
    transition: 100ms;
    color: #4a4a4a;
    &:placeholder {
      color: #ccc;
    }
    ${({ hasFocus }) =>
      hasFocus
        ? `
      cursor: text;
      border: 3px solid #25c1a9;
      `
        : `
      cursor: pointer;
      border: 3px solid white;
    `}
  }
`
