import React from "react"
import { Button } from "@yarno/thread"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import RichText from "../components/RichText"
import linkResolver from "../utils/richTextLinkResolver"
import { md } from "../utils/breakpoints"

import Layout from "../components/Layout"
import Meta from "../components/Meta"
import SupportIndex from "../components/SupportIndex"
import SupportContact from "../components/SupportContact"
import AlgoliaSearch from "../components/AlgoliaSearch"

import Waves from "../components/Waves"

const Support = ({ data, pageContext, location }) => {
  const edges = data.prismicSupport.data.body
  const {
    support_main_title,
    support_main_image,
    support_main_byline,
    support_search_placeholder_text,
    contact_form_button,
    meta_title,
    meta_description
  } = data.prismicSupport.data

  const environment = process.env.GATSBY_CLOUD
    ? process.env.GATSBY_IS_PREVIEW === true
      ? "preview"
      : "production"
    : "development"

  const searchIndices = [
    {
      name: `${environment}_support_index`,
      title: `${environment}_support_index`
    }
  ]
  return (
    <Layout location={location} prismicLayout={data.prismicLayout} hideSalesCTA>
      <Meta
        title={meta_title ? meta_title : "Yarno Support"}
        description={meta_description ? meta_description : "Yarno Support"}
        canonical={location.href}
      />
      <TopBanner>
        <Content>
          <h1>
            <i className="fa fa-lifebuoy" />
            {support_main_title}
          </h1>
          <AlgoliaSearch
            indices={searchIndices}
            placeholder={support_search_placeholder_text}
          />
          {support_main_byline && (
            <Byline>
              <RichText
                render={support_main_byline.richText}
                linkResolver={linkResolver}
              />
            </Byline>
          )}
          <SupportButton>
            <Link to="/support/contact">
              <Button secondary label={contact_form_button} />
            </Link>
          </SupportButton>
          <Image>
            <GatsbyImage
              image={getImage(support_main_image)}
              alt={support_main_title}
            />
          </Image>
        </Content>
        <Waves />
      </TopBanner>

      {edges.map((item, index) => {
        return <SupportIndex key={item.id} data={item} />
      })}
      <SupportContact />
    </Layout>
  )
}

export default Support

export const query = graphql`
  query SupportPageQuery {
    ...PrismicLayout
    prismicSupport {
      ...SupportPage
    }
  }
`

const TopBanner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 0 0;
  position: relative;
  margin-bottom: 50px;
  justify-content: center;
  background-color: #1aa38e;
  text-align: center;
  @media ${md} {
    padding: 8rem 0 0 0;
  }

  h1 {
    color: #fff;
    font-weight: 700;
    margin: 0 0 3rem 0;
    i {
      margin-right: 10px;
    }

    @media ${md} {
      margin-bottom: 4rem;
    }
  }
`
const Content = styled.div`
  max-width: 600px;
  margin: 0 auto 5rem;
  width: 100%;
  padding: 0 15px;
  @media ${md} {
    margin-bottom: 10rem;
  }
`

const Byline = styled.div`
  p {
    color: white;
    margin-bottom: 0;
    font-size: 1.6rem;
    a {
      color: white;
      text-decoration: underline;
    }

    @media ${md} {
      font-size: 1.8rem;
    }
  }
`

const Image = styled.div`
  display: none;
  right: 100px;
  bottom: -32px;
  z-index: 1;
  @media ${md} {
    display: block;
    position: absolute;
  }
`

const SupportButton = styled.div`
  margin-top: 2rem;
`
