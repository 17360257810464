import styled from "styled-components"
import { Link } from "gatsby"
import { sm, md, lg } from "../../utils/breakpoints"

export const Svg = styled.div`
  position:absolute;
  right:-1px;
  bottom:-1px;
  &:before {
    transition: border-bottom 0.3s ease;
    content:'';
    position:absolute;
    bottom:0;
    width:0;
    height:0;
    right:0;
    border-bottom: 60px solid #25c1a9;
    border-left: 60px solid transparent;
  }
  svg {
    z-index:1;
    position:absolute;
    bottom: 15px;
    right: 10px;
  }

  @media ${md} {
    &:before {
      border-bottom: 70px solid #25c1a9;
      border-left: 70px solid transparent;
    }
    svg {
      bottom: 20px;
      right: 15px;
    }
  }
`

export const Card = styled(Link)`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  background-color: #F8F8F8;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  min-height: 12rem;
  max-height: 14rem;

  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    top: 2px;
    div:nth-of-type(3) {
      &:before {
        border-bottom: 60px solid #f9a21a;
      }
    }
  }

  div:nth-of-type(2) {
    flex: 2;
  }
 

  h5 {
    font-weight: 600;
    font-size: 2rem;
    color: #25c1a9;
    margin-top: 0;
  }

  p {
    font-weight: 200;
    font-size: 1.5rem;
    color: #888;
    margin-bottom: 0;
    padding-right: 2.5rem;
  }

  & > a {
    text-decoration: none;
  }

  @media ${sm} {
    flex-direction: column;
    text-align: center;
    min-height: 20rem;
    max-height: 26rem;
    padding: 2rem;
    height: unset;

    h5 {
      margin-top: 1rem;
    }

    p {
      padding-right: 0;
      max-width: 80%;
      margin: 0 auto;
    }
  }

  @media ${md} {
    padding-bottom: 3rem;
    max-height: 30rem;
    h5 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.7rem;
    }

    &:hover {
      div:nth-of-type(3) {
        &:before {
          border-bottom: 70px solid #f9a21a;
        }
      }
    }
  }

  @media ${lg} {
    img {
      height: 15rem;
    }
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 12rem;
  margin-right: 1rem;

  img {
    max-height: 10rem;
    object-fit: contain;

    @media ${md} {
      max-height: 13rem;
    }

    @media ${lg} {
      max-height: 15rem;
    }
  }

  @media ${sm} {
    max-width: unset;
    margin-right: 0;
  }
`