import styled from "styled-components"
import { md } from "../../utils/breakpoints"

export const FormContainer = styled.div`
  border-radius: 7px;
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
  position: relative;
`
