import React, { createRef, useState, useMemo } from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch } from "react-instantsearch-dom"
import SearchBox from "./Box"
import SearchResult from "./Result"
import useClickOutside from "../../hooks/useClickOutside"
import { FormContainer } from "./styles"

const Search = ({ indices, placeholder }) => {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  useClickOutside(rootRef, () => setFocus(false))

  return (
    <FormContainer ref={rootRef}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}>
        <SearchBox
          onFocus={() => setFocus(true)}
          hasFocus={hasFocus}
          placeholder={placeholder}
        />
        <SearchResult
          show={query && query.length > 0 && hasFocus}
          indices={indices}
        />
      </InstantSearch>
    </FormContainer>
  )
}

export default Search
