import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card, ImageContainer, Svg } from "./styles"

const SupportCategoryCard = ({ data }) => {
  const { document } = data.category
  if (document === null) return null

  const { title, byline, image } = document.data
  const postLink = `/support/category/${document.uid}/`

  return (
    <Card to={postLink}>
      <ImageContainer>
        <GatsbyImage
          image={getImage(image)}
          imgStyle={{
            objectFit: "contain"
          }}
          layout="constrained"
          alt={title.text}
        />
      </ImageContainer>
      <div>
        <h5>{title.text}</h5>
        {byline && <p>{byline.text}</p>}
      </div>
      <Svg>
        <svg
          width="16"
          height="10"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 4.20502H11M11 4.20502L8.5 1.53418M11 4.20502L8.5 6.87586"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Svg>
    </Card>
  )
}

export default SupportCategoryCard
