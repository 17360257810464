import styled from "styled-components"
import { Link } from "gatsby"

export const Results = styled.div`
  display: ${props => (props.show ? `block` : `none`)};
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 100%;
  margin-top: -0.2rem;
  width: inherit;
  border-radius: 2px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: white;
  border: 1px solid #25c1a9;

  h6 {
    text-align: left;
    font-weight: bold;
    color: #bbb;
    text-transform: uppercase;
    margin: 0;
    padding: 1.2rem;
  }

  ul {
    list-style: none;
    margin: 0;
    margin-left: 0;
    margin-bottom: 0;
    padding: 0;
    text-align: left;
  }
  li {
    font-size: 1.4rem;
    &:hover {
      background-color: #eee;
    }
  }

  mark.ais-Highlight-highlighted {
    background-color: #d6ede8 !important;
  }
`

export const Hit = styled(Link)`
  display: block;
  padding: 1.3rem 2rem;
  font-size: 1.5rem;
  color: #595959;
`
