import styled from "styled-components"
import { sm, md, lg, xl } from "../../utils/breakpoints"

export const Section = styled.div`
  margin-bottom: 60px;
  @media ${md} {
    padding: 0px 2.5rem;
  }
`
export const ContentBlock = styled.div`
  position: relative;
  padding: 2rem 0 0 1.5rem;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -2px;
    width: 280px;
    height: 2px;
    background: linear-gradient(
      90deg,
      #25c1a9 8.03%,
      rgba(37, 193, 169, 0) 91.16%
    );
    @media ${sm} {
      width: 445px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 179px;
    left: -182px;
    width: 360px;
    height: 2px;
    background: linear-gradient(
      90deg,
      #25c1a9 8.03%,
      rgba(37, 193, 169, 0) 91.16%
    );
    transform: rotate(90deg);
  }
`

export const Header = styled.div`
  h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 38px;
    color: #25c1a9;
    margin: 10px 0 0 0;
  }

  p {
    color: #595959;
    margin-top: 0;
    font-size: 1.5rem;
  }

  @media ${md} {
    h2 {
      font-size: 2.8rem;
    }
    p {
      font-size: 1.8rem;
    }
  }
`

export const GridInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  @media ${sm} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${md} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${lg} {
    grid-template-columns: repeat(4, 1fr);
  }
`
