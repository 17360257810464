import React from "react"
import Container from "../common/Container"
import SupportCategoryCard from "../SupportCategoryCard"
import { Section, ContentBlock, GridInner, Header } from "./styles"

const SupportIndex = ({ data }) => {
  const { title, byline } = data.primary
  return (
    <Section>
      <Container>
        <Header>
          <h2>{title.text}</h2>
          <p>{byline.text}</p>
        </Header>
        <ContentBlock>
          <GridInner>
            {data.items &&
              data.items.map((item, index) => (
                <SupportCategoryCard key={index} data={item} />
              ))}
          </GridInner>
        </ContentBlock>
      </Container>
    </Section>
  )
}
export default SupportIndex
