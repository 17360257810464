import React from "react"
import {
  connectStateResults,
  connectHits,
  Highlight,
  Index
} from "react-instantsearch-dom"
import { Results, Hit } from "./styles"

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? null : <p>No results</p>
})

const Hits = ({ hits }) => {
  const categoryHits = hits.filter(hit => hit.type === "Category")
  const articleHits = hits.filter(hit => hit.type === "Article")

  return (
    <div>
      {categoryHits.length > 0 && (
        <React.Fragment>
          <h6>Categories ({categoryHits.length})</h6>
          <ul>
            {categoryHits.map(hit => (
              <li key={hit.objectID}>
                <Hit to={hit.slug}>{hit.title}</Hit>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
      {articleHits.length > 0 && (
        <React.Fragment>
          <h6>Articles ({articleHits.length})</h6>
          <ul>
            {articleHits.map(hit => (
              <li key={hit.objectID}>
                <Hit to={hit.slug}>{hit.title}</Hit>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
    </div>
  )
}

const CustomHits = connectHits(Hits)

const PageHit = ({ hit }) => {
  return (
    <Hit to={hit.slug}>
      <Highlight attribute="title" hit={hit} tagName="mark" />
    </Hit>
  )
}

const HitsInIndex = ({ index }) => {
  return (
    <Index indexName={index.name}>
      <HitCount />
      <CustomHits hitComponent={PageHit} />
    </Index>
  )
}

const SearchResult = ({ indices, show }) => (
  <Results show={show}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </Results>
)

export default SearchResult
